.ShopContainer {
  min-height: 400px;
  position: relative;
  --color-success: #1daf4e;

  .vertical-line {
    height: 3px;
    width: 80%;
    margin: 30px auto;
    background-color: var(--bdgclInp);
  }

  .navigation {
    display: flex;
    justify-content: space-evenly;
    padding: 0 20px;

    .NavigationBubble {
      --scale: 1;
      width: 86px;
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        aspect-ratio: 1;
        width: 100%;
        border-radius: 1000px;
        background-color: var(--bdgclInp);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        > svg {
          transform: scale(var(--scale));
        }
      }

      &.active > div {
        background-color: #008aff;
      }

      &.done > div {
        background-color: var(--color-success);
      }

      &.disabled > div {
        cursor: not-allowed;
        opacity: 0.5;
      }

      p {
        font-size: 11px;
      }
    }

    .line {
      margin-top: 43px;
      height: 3px;
      width: 3vw;
      background-color: var(--bdgclInp);

      &.active {
        background-color: var(--color-success);
      }
    }
  }

  .StoreSettingsForm {
    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;
      background-color: var(--backgroundGray);
      border: 5px dashed var(--border-dark);
      border-radius: 10px;
      cursor: pointer;

      input {
        display: none;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          margin-bottom: 0;
        }
      }

      > img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
      }
    }

    form {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .grp-fm {
        width: 100%;
      }

      button {
        min-width: 200px;
        margin-top: 30px;
      }
    }
  }

  .ThemeSettingsForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
    }

    .themes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px 30px;

      img {
        position: relative;
        border: var(--border-dark) 4px solid;
        border-radius: 5px;
        max-width: 100%;
        cursor: pointer;
        transition: opacity 200ms;
        height: auto;

        &:hover {
          opacity: 0.8;
        }

        &.active {
          border-color: #008aff;
        }
      }
    }

    button {
      min-width: 200px;
      margin-top: 30px;
    }
  }

  .SubdomainSettingsForm,
  .FacebookSettingsForm {
    margin: 60px 30px 0 30px;
    position: relative;

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      > div {
        min-width: 180px;
        cursor: pointer;
        margin: 0 10px;
      }
    }

    .link {
      font-weight: 800;
      color: #008aff;
      margin-left: 3px;
    }
    .subdomain-container {
      position: relative;
      .subdomain-url {
        position: absolute;
        right: 10px;
        bottom: 11px;
        pointer-events: none;
      }
      &:not(:first-child) {
        margin-top: 50px;
      }

      h2 {
        margin: 0;
      }
    }
    .connect-domain-button {
      width: 200px;
    }
  }

  .FinishSettingShop {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main {
      background-color: var(--bdgclInp);
      width: 555px;
      display: grid;
      grid-template-columns: 1fr 2fr;
      border-radius: 3px;
      padding-bottom: 20px;

      img {
        --logo-spacing: 32px;
        max-width: calc(100% - (var(--logo-spacing) * 2));
        margin: 22px var(--logo-spacing);
        border-radius: 1000px;
      }

      .SubElement {
        margin-top: 20px;
        p {
          margin: 0;
        }

        .value {
          font-weight: 800;
          font-size: 20px;
        }
      }
    }

    .publish button {
      margin-top: 30px;
      width: 256px;
    }
  }
}

.ShopSettingMain {
  .links {
    margin-top: 60px;

    .link {
      padding: 25px 20px;
      background-color: var(--defcl);
      margin-bottom: 22px;
      border-radius: 20px;
      border: 1px solid rgba(207, 207, 207, 0.11);
      display: flex;
      align-items: center;

      .arrow {
        margin-inline-start: auto;
        transform: scale(1.5);
      }
      .icon {
        transform: scale(1.3);
      }
      .texts {
        margin-inline-start: 25px;

        h2,
        p {
          margin: 0;
        }
        h2 {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.LandingPageContainer {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 40px;

  * {
    margin: 0;
  }
  .landingPage-container {
    background-color: var(--defcl);
    border-radius: 20px;
    margin: 20px 0;
    padding: 10px;
  }
  .links {
    .link {
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 54px;
      border-bottom: 1px solid #313d4f;
      padding-inline-start: 10px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &.active {
        background-color: #008aff;
      }
      .arrow {
        transform: scale(1.3);
        display: flex;
      }
    }
  }
}

.LandingPageHeader,
.LandingPageFooter,
.LandingPageCongratulation,
.LandingPageColors {
  position: relative;
  min-height: 161px;
  padding-bottom: 90px;

  .button {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.LandingPageColors {
  min-height: 330px;

  .sketch-picker input {
    color: black;
  }
  .color-container {
    height: 31px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    position: relative;
  }
  .color-box {
    width: 51px;
    height: 31px;
    border-radius: 4px;
    cursor: pointer;
    margin-inline-end: 10px;
  }
  .color-title {
    width: 185px;
  }
  .color-gif {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    opacity: 0;
    transition: opacity 200ms;
    max-width: 300px;
    z-index: 2;
    pointer-events: none;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
  .color-container:hover .color-gif {
    opacity: 1;
  }
}

html[dir="rtl"] {
  .SubdomainSettingsForm input {
    text-align: left;
  }

  .LandingPageHeader,
  .LandingPageFooter,
  .LandingPageCongratulation,
  .LandingPageColors {
    .button {
      position: absolute;
      right: auto;
      left: 0;
    }
  }
}

@media only screen and (max-width: 920px) {
  .responsive-computer {
    display: none !important;
  }

  .FlHg.StVlWdt.ClSidTh.StBxSh.stbd2.OvfAtoY.StBgbrd.HdOvFlwX.StMerV.StPs.StTpNl {
    position: static;
    margin-bottom: 50px;
  }

  .ShopContainer {
    margin-bottom: 50px;

    .navigation {
      flex-direction: column;
      padding: 0;

      .NavigationBubble {
        flex-direction: row;
        width: 100%;
        margin: 15px 0px;
        > div {
          width: 60px;
        }

        > p {
          margin-left: 10px;
          font-size: 18px;
        }
      }

      .line {
        margin: 0px 29px;
        height: 17px;
        width: 3px;
      }
    }

    .children {
      margin: 15px 0;
    }

    .ThemeSettingsForm {
      > p {
        margin-top: 0;
      }
      .themes {
        grid-template-columns: 1fr;
      }
    }

    .SubdomainSettingsForm,
    .FacebookSettingsForm {
      margin: 0px;

      .subdomain-url {
        display: none;
      }
      .buttons {
        flex-direction: column;

        > div:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    .FinishSettingShop .main {
      max-width: 100%;

      img {
        max-width: 44px;
        margin: 10px;
      }

      .SubElement .title {
        font-size: 12px;
      }

      .SubElement .value {
        font-size: 14px;
      }
    }
  }
  .ShopPublishedSuccess svg {
    height: auto !important;
  }

  .LandingPageContainer {
    display: block;
  }
}

@media only screen and (min-width: 921px) {
  .responsive-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 621px) {
  .ShopSettingMain .link .arrow {
    display: none;
  }
}
